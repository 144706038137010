import React from 'react'
import { MostRecent, MostPopular, TodayPhoto } from './sideboards'

const SideBoard = ({ popularArticles, recentArticles }) => (
  <div className="mb-6 md:mb-0">
    <TodayPhoto />
    <MostPopular articles={popularArticles} />
    <MostRecent articles={recentArticles} />
  </div>
)

export default SideBoard
