import React, { useEffect, useReducer } from 'react'
import fetch from 'node-fetch'
import { createApi } from 'unsplash-js'
import { openLink } from '../../utils'
import { unsplashCollections, defaultImg } from '../../constants'
import TitleBar from '../title-bar'
import '../../styles/loading.scss'

const defaultPicture = {
  unsplash: null,
  urls: {
    regular: defaultImg
  },
  links: {
    download: defaultImg,
    download_location: defaultImg
  },
  user: {
    name: 'Hack Capital',
    links: {
      html: 'https://unsplash.com/@hackcapital?utm_source=Daseveny&utm_medium=referral'
    }
  },
  photoPlatform: { name: 'Unsplash', url: 'https://unsplash.com' }
}

const downloadPhoto = (app, location) => {
  try {
    app.photos.trackDownload({
      downloadLocation: location
    })
  } catch (error) {
    console.error('Download cover photo failed: ', error.message)
  }
}

const TodayPhoto = () => {
  const photoDataReducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_PHOTO_DATA':
        return { ...state, ...action.payload }
      default:
        throw new Error('Invalid action type')
    }
  }

  let [photoState, dispatch] = useReducer(photoDataReducer, {
    unsplash: null,
    urls: {
      regular: ''
    },
    links: {
      download: '',
      download_location: ''
    },
    user: {
      name: '',
      links: {
        html: ''
      }
    },
    photoPlatform: defaultPicture.photoPlatform
  })

  useEffect(() => {
    ;(async () => {
      const KEY = process.env.GATSBY_UNSPLASH_ACCESS_KEY
      const defaultData = {
        ...defaultPicture,
        photoPlatform: defaultPicture.photoPlatform
      }
      if (KEY) {
        const pictureApp = createApi({ accessKey: KEY, fetch })
        pictureApp.photos
          .getRandom({ collectionIds: unsplashCollections })
          .then((picture) => {
            const data = {
              unsplash: pictureApp,
              ...picture.response,
              photoPlatform: defaultPicture.photoPlatform
            }
            dispatch({ type: 'UPDATE_PHOTO_DATA', payload: data })
          })
          .catch((error) => {
            dispatch({
              type: 'UPDATE_PHOTO_DATA',
              payload: { unsplash: pictureApp, ...defaultData }
            })
          })
      } else {
        dispatch({ type: 'UPDATE_PHOTO_DATA', payload: defaultData })
      }
    })()
  }, [])

  const {
    unsplash,
    urls,
    user,
    links,
    alt_description,
    photoPlatform: platform
  } = photoState
  const userUTM = `${
    user && user.links && user.links.html
  }?utm_source=Daseveny&utm_medium=referral`

  return (
    <div
      className="shadow-md pt-3 md:pt-6 px-4 md:px-6 pb-2 md:pb-4 mb-1 md:mb-2"
      style={{
        backgroundColor: 'var(--bg-card)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      <TitleBar title="រូបភាពជ្រើសរើស" show={false} />
      <div
        className="flex justify-center items-center border-2 mb-0.5 p-0.25 rounded-md w-full md:w-35-v min-h-25-v"
        style={{
          borderColor: 'var(--textNormal)',
          backgroundColor: 'var(--bgMute)'
        }}
      >
        {urls && urls.regular ? (
          <img
            loading="lazy"
            src={urls.regular}
            alt={alt_description}
            className="cursor-pointer rounded-img-p w-full md:w-35-v h-30-v md:h-25-v ml-0.01 mb-0.01"
            style={{ objectFit: 'cover' }}
            onClick={() => {
              downloadPhoto(unsplash, links.download_location)
              openLink(links.download)
            }}
          ></img>
        ) : (
          <div className="flex justify-center items-center w-full md:w-35-v h-30-v md:h-25-v">
            <div className="lds-ripple my-10">
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      {user && user.name ? (
        <div
          className="flex w-full justify-center items-center px-2 py-2 text-gray-600"
          style={{ fontSize: '0.75rem' }}
        >
          រូបថត​ដោយ៖
          <div
            className="cursor-pointer text-indigo-700 hover:text-red-400 mx-1"
            onClick={() => openLink(userUTM)}
          >
            {user && user.name.split(' ')[0]}
          </div>
          នៅ
          <div
            className="cursor-pointer text-pink-500 hover:text-red-400 ml-1"
            onClick={() => openLink(platform.url)}
          >
            {platform.name}
          </div>
        </div>
      ) : (
        <div
          className="flex w-full justify-center items-center px-2 py-2 text-gray-600"
          style={{ fontSize: '0.75rem' }}
        >
          កំពុងឡូដ...
        </div>
      )}
    </div>
  )
}

export { TodayPhoto }
