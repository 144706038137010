import React from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MostItem = ({ article }) => {
  return (
    <div
      className="flex w-full mb-4 cursor-pointer"
      onClick={() => navigate(article.node.fields.slug)}
    >
      <div
        className="border-2 p-0.25 rounded-md mr-2"
        style={{ borderColor: 'var(--textNormal)' }}
      >
        <GatsbyImage
          className="rounded-img-p ml-0.01 mt-0.01"
          imgClassName="rounded-img-p"
          image={getImage(article.file.node)}
          alt="Article's thumbnail"
          objectFit="cover"
          objectPosition="center center"
        />
      </div>
      <p className="hover:text-red-400">{article.node.frontmatter.title}</p>
    </div>
  )
}

export { MostItem }
