import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { MainNavbar, SubNavbar } from '../components/heading'
import SideBoard from '../components/sideboard'
import Footer from '../components/heading/footer'
import Singularity from '../components/singularity'
import { GetAllMarkdownRemarks } from '../queries/all-markdowns'

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query MostPopularQuery {
      allPageViews(sort: { fields: totalCount, order: DESC }) {
        nodes {
          id
          totalCount
          path
        }
      }
      allFile(filter: { ext: { ne: ".md" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 38, height: 38)
            }
          }
        }
      }
    }
  `)

  // Filter about paths which are not articles
  const viewCountBlacklist = [
    '/about',
    '/blogs',
    '/programmings',
    '/technologies',
    '/tags',
    '/writers',
    '/privacy-policy',
    '/terms-conditions'
  ]

  let mostPopular = data.allPageViews.nodes.filter(({ path }) => {
    let blackListed = false
    viewCountBlacklist.forEach((test) => {
      if (path.includes(test)) {
        blackListed = true
      }
    })
    return path !== '/' && !blackListed
  })

  // Get only 5 items, the most popular
  mostPopular = mostPopular.filter((n) => n && n.path)
  mostPopular.length = 5

  // Get the matching article details
  const top5Articles = []
  const { allMarkdownRemark } = GetAllMarkdownRemarks()
  mostPopular.forEach((viewItem) => {
    const article = allMarkdownRemark.edges.find(
      (edge) => edge.node.fields.slug === viewItem.path
    )
    top5Articles.push(article)
  })

  // Replace Images
  const allFile = data.allFile
  const top5ArticlesWithImage = []
  for (let i = 0; i < top5Articles.length; i++) {
    const img = allFile.edges.find((edge) => {
      const featured = top5Articles[i].node.frontmatter.featuredImage
      if (featured) {
        return edge.node.base === featured.base
      } else {
        return edge.node.base === 'new-default-cover.png'
      }
    })
    const articleWithImg = { ...top5Articles[i], file: img }
    top5ArticlesWithImage.push(articleWithImg)
  }

  // Get most recent articles 5 items
  const mostRecentArticles = allMarkdownRemark.edges.slice(0, 5)

  // Replace Images
  const mostRecentArticlesWithImage = []
  for (let i = 0; i < mostRecentArticles.length; i++) {
    const img = allFile.edges.find((edge) => {
      const featured = mostRecentArticles[i].node.frontmatter.featuredImage
      if (featured) {
        return edge.node.base === featured.base
      } else {
        return edge.node.base === 'new-default-cover.png'
      }
    })
    const articleWithImg = { ...mostRecentArticles[i], file: img }
    mostRecentArticlesWithImage.push(articleWithImg)
  }

  return (
    <div className="flex flex-col justify-start items-center w-full md:h-full-v">
      <MainNavbar location={location} />
      <SubNavbar location={location} />
      <div
        className={`w-full max-w-screen-sm md:max-w-screen-md mb-2 px-1 md:px-0`}
      >
        <div className="flex flex-col md:flex-row">
          <main className="md:mr-2">{children}</main>
          <SideBoard
            popularArticles={top5ArticlesWithImage}
            recentArticles={mostRecentArticlesWithImage}
          />
        </div>
        <Singularity location={location} />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
