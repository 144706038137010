import React from 'react'
import { Link } from 'gatsby'

const Icon = ({ name, hovered }) => {
  switch (name) {
    case 'អំពីដាសេវេនី':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>About</title>
          <path d="M12.76 3.76a6 6 0 018.48 8.48l-8.53 8.54a1 1 0 01-1.42 0l-8.53-8.54a6 6 0 018.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 10-5.66-5.66l-1.46 1.47a1 1 0 01-1.42 0L9.83 5.17a4 4 0 10-5.66 5.66L12 18.66l7.83-7.83z" />
        </svg>
      )
    case 'Code':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>Code</title>
          <path d="M.7 9.3l4.8-4.8 1.4 1.42L2.84 10l4.07 4.07-1.41 1.42L0 10l.7-.7zm18.6 1.4l.7-.7-5.49-5.49-1.4 1.42L17.16 10l-4.07 4.07 1.41 1.42 4.78-4.78z" />
        </svg>
      )
    case 'Latest':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>Latest</title>
          <path d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z" />
        </svg>
      )
    case 'Podcast':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>Podcast</title>
          <path d="M200.529 227.287c0 19.445 11.081 36.255 27.257 44.523v132.643c0 9.992-8.181 18.172-18.17 18.172h-49.972c-12.536 0-22.713 10.175-22.713 22.716 0 12.536 10.177 22.711 22.713 22.711h181.708c12.54 0 22.716-10.175 22.716-22.711 0-12.541-10.176-22.716-22.716-22.716h-49.97c-9.988 0-18.168-8.18-18.168-18.172V271.81c16.166-8.268 27.254-25.078 27.254-44.523 0-27.621-22.349-49.97-49.969-49.97-27.621 0-49.97 22.349-49.97 49.97zm-72.684 1.818c0 22.713 6.086 43.973 16.628 62.329l1.366 2.086c3.717 7.442 11.356 12.536 20.167 12.536 12.536 0 22.713-10.267 22.713-22.979 0-4.367-1.189-8.455-3.272-11.906l-2.459-4.18c-6.174-11.268-9.714-24.169-9.714-37.886 0-43.067 34.62-79.045 77.227-79.045 42.606 0 77.227 35.978 77.227 79.045 0 13.717-3.549 26.618-9.724 37.886l-2.448 4.18c-2.095 3.451-3.275 7.539-3.275 11.906 0 12.713 10.176 22.979 22.715 22.979 8.808 0 16.438-5.094 20.167-12.536l1.364-2.086c10.542-18.356 16.629-39.616 16.629-62.329 0-68.417-54.876-124.473-122.655-124.473s-122.656 56.056-122.656 124.473zm-72.683 0c0 44.423 14.533 85.398 39.163 118.385l1.633 2.273c4.08 5.624 10.807 9.263 18.26 9.263 12.536 0 22.713-10.268 22.713-22.892 0-5.269-1.721-10.188-4.721-14.088l-1.729-2.361c-18.811-25.252-29.893-56.606-29.893-90.58 0-83.588 67.139-151.73 149.912-151.73 82.764 0 149.909 68.142 149.909 151.73 0 33.974-11.088 65.328-29.891 90.58l-1.73 2.361c-2.996 3.9-4.719 8.819-4.719 14.088 0 12.624 10.175 22.892 22.715 22.892 7.443 0 14.176-3.639 18.26-9.263l1.632-2.273c24.63-32.986 39.164-73.962 39.164-118.385 0-108.938-87.495-197.156-195.339-197.156-107.847 0-195.339 88.219-195.339 197.156z" />
        </svg>
      )
    case 'ប្រូក្រាមីង':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>ប្រូក្រាមីង</title>
          <path d="M20.59 12l-3.3-3.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l3.3-3.3zM3.4 12l3.3 3.3a1 1 0 0 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L3.4 12zm7.56 8.24a1 1 0 0 1-1.94-.48l4-16a1 1 0 1 1 1.94.48l-4 16z" />
        </svg>
      )
    case 'បច្ចេកវិទ្យា':
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>បច្ចេកវិទ្យា</title>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.5"
            d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
          />
        </svg>
      )
    default:
      return (
        <svg
          className={`fill-current ${
            hovered ? 'text-red-400' : ''
          } h-5 w-5 mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          style={{
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out'
          }}
        >
          <title>Code</title>
          <path d="M.7 9.3l4.8-4.8 1.4 1.42L2.84 10l4.07 4.07-1.41 1.42L0 10l.7-.7zm18.6 1.4l.7-.7-5.49-5.49-1.4 1.42L17.16 10l-4.07 4.07 1.41 1.42 4.78-4.78z" />
        </svg>
      )
  }
}

const TitleBar = ({ title, location, to, show = true, clickable }) => {
  return (
    <div
      className={`flex flex-col max-w-screen-sm shadow-none md:max-w-screen-md lg:max-w-screen-lg w-full md:mb-2`}
      style={{
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      <div className="flex items-center justify-between mb-1">
        <div className="flex items-center">
          {show ? <Icon name={title} hovered={false} /> : null}
          <span>{title}</span>
        </div>
        {show ? (
          <div className="flex items-center">
            តាមដាន៖
            <a
              className="flex flex-col items-center hover:text-red-400 shadow-none ml-2"
              href="https://twitter.com/daseveny"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="fill-current text-blue-800 h-5 w-5"
                style={{ color: 'rgb(100, 174, 249)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 920 1000"
              >
                <path d="M920 214c-24 36-55.333 68.667-94 98v24c0 86.667-20 170-60 250S664 733.667 580 789s-180.667 83-290 83c-106.667 0-203.333-28-290-84 9.333 1.333 24.667 2 46 2 88 0 166-26.667 234-80-41.333-1.333-78-14-110-38s-54-55.333-66-94c6.667 2.667 18 4 34 4 17.333 0 34-2 50-6-44-9.333-80-31.333-108-66s-42-74.667-42-120v-2c24 13.333 52 21.333 84 24-56-38.667-84-91.333-84-158 0-32 8.667-63.333 26-94 102.667 125.333 232.667 190.667 390 196-4-12-6-26-6-42 0-52 18.333-96.333 55-133s81.667-55 135-55c54.667 0 100 19.333 136 58 40-8 80-22.667 120-44-13.333 44-40.667 78.667-82 104 37.333-5.333 73.333-15.333 108-30" />
              </svg>
            </a>
            <Link className="shadow-none ml-1" to="/rss.xml">
              <svg
                className="fill-current h-4 w-4"
                style={{ color: 'rgb(234, 170, 0)' }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 760 1000"
              >
                <path d="M0 120c209.333 0 388.333 74.333 537 223 148.667 148.667 223 327.667 223 537H642c0-177.333-62.667-328.333-188-453C328.667 302.333 177.333 240 0 240V120m0 238c145.333 0 269 51 371 153s153 225 153 369H406c0-110.667-39.667-205.667-119-285S112 476 0 476V358m114 296c30.667 0 57.333 11 80 33s34 49 34 81c0 30.667-11.333 57-34 79s-49.333 33-80 33-57.333-11-80-33-34-48.333-34-79c0-32 11.333-59 34-81s49.333-33 80-33" />
              </svg>
            </Link>
          </div>
        ) : null}
      </div>
      <div
        className={`w-full mb-4 md:mb-2 border-0 border-b-2`}
        style={{
          borderColor: 'var(--textNormal)',
          transition: 'borderColor 0.2s ease-out, background 0.2s ease-out'
        }}
      ></div>
    </div>
  )
}

export default TitleBar
