import React from 'react'
import TitleBar from '../title-bar'
import { MostItem } from './most-item'

const MostPopular = ({ articles }) => {
  return (
    <div
      className="shadow-md pt-3 md:pt-6 px-4 md:px-6 pb-2 md:pb-4 mb-1 md:mb-2"
      style={{
        color: 'var(--textNormal)',
        backgroundColor: 'var(--bg-card)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      <TitleBar title="អត្ថបទ​ពេញនិយម" show={false} />
      <div className="text-sm mt-1 md:mt-3">
        {articles.map((item) => {
          return <MostItem article={item} key={item.node.id} />
        })}
      </div>
    </div>
  )
}

export { MostPopular }
