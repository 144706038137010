import { useStaticQuery, graphql } from 'gatsby'

export const GetAllMarkdownRemarks = () => {
  const data = useStaticQuery(
    graphql`
      query AllMarkdownRemarkQuery {
        allMarkdownRemark(
          filter: { frontmatter: { published: { eq: true } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                featuredImage {
                  id
                  base
                }
              }
            }
          }
        }
      }
    `
  )

  return data
}
